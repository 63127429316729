import "./Footer.css"
import { FaDiscord, FaTwitter, FaFacebookF, FaFacebook, FaInstagram } from "react-icons/fa"
const Footer = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-md-3 text-center">
            <a href="https://twitter.com/ExclusiveSharks" target="_blank">
              <FaTwitter className="social-icon" />
            </a>
            <a href="https://discord.gg/QVCRBBkw4h" target="_blank">
              <FaDiscord className="social-icon" />
            </a>

          </div>
          <div className="col-md-5"></div>
          <div className="col-md-4 m-auto text-center ">
            <span className="text-white text-center">Copyright Exclusive Shark Club</span>
          </div>
        </div>

      </div>
    </>
  );
}

export default Footer;
