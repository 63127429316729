import "./Team.css"
import wiz from "../../images/wiz.jpeg"
import brad from "../../images/brad.png"
import trip from "../../images/team.jpeg"
import zoe from "../../images/zoe.png"
import shop from "../../images/shop-mobile.jpeg"
import equationShark from "../../images/desktop_equation_v2.png"
import mobilejawn from "../../images/mobile_shop_v3.png"
import lilJawn from "../../images/lilJawn.png"


const Team = () => {
    const width = window.innerWidth;
    const maxSize = 455;
    return (
        <>
            <div className="container background-color-sections bg-team" >
                <div className="row laptop p-3 home-section-one ">
                    <div className="col-md-12 m-auto text-center shop "
                        data-aos="fade-up"
                        data-aos-offset="00"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <div className="col-md-4 py-2 my-4 background-color-section">
                            <h1 className="text-center">Shark Evolution Shop</h1>
                            <p>
                                There are many ways to evolve your Shark and the place to do it is here at the Evolution Shop. This is where you will find everything you will need to evolve your Shark into its most exclusive form. Different items come with different perks for your Shark. It’s up to you to decide what your Shark needs. All items are purchased with $ESC.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mobile p-3 home-section-one ">
                    <div className="col-md-6 m-auto text-center"
                        data-aos="fade-up"
                        data-aos-offset="00"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <img src={mobilejawn} alt="" />
                    </div>
                    <div className="col-md-6 bg-image home-section-one background-color-sections text-center p-2 "
                        data-aos="fade-up"
                        data-aos-offset="00"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <div className="col-md-4 py-2 my-4 ">
                            <h1 className="text-center">Shark Evolution Shop</h1>
                            <p>
                                There are many ways to evolve your Shark and the place to do it is here at the Evolution Shop. This is where you will find everything you will need to evolve your Shark into its most exclusive form. Different items come with different perks for your Shark. It’s up to you to decide what your Shark needs. All items are purchased with $ESC.
                            </p>
                        </div>
                    </div>

                </div>

                {width > maxSize ? (
                    <div className="col-lg py-2 my-4">

                        <img src={equationShark} alt="" className='' />
                    </div>


                ) : null}



            </div>
            <div className="container home-section-one background-color-sections bg-team" id="team">
                <h1 className="text-center">Team</h1>
                <div className="row team-section">
                    <div className="my-3 col-xl-3 col-lg-3 col-md-6 col-6 text-center"
                        data-aos="fade-up"
                        data-aos-offset="0"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <img src={trip} alt="" className="img-fluid" />
                        <h5 className="py-2 text-white text-center">
                            Trip
                        </h5>
                        <h5 className="py-2 text-white text-center">
                            Founder
                        </h5>

                    </div>
                    <div className="my-3 col-xl-3 col-lg-3 col-md-6 col-6 text-center"
                        data-aos="fade-up"
                        data-aos-offset="0"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <img src={zoe} alt="" className="img-fluid" />
                        <h5 className="py-2 text-white text-center">
                            Zo
                        </h5>
                        <h5 className="py-2 text-white text-center">
                            Founder/ Artist

                        </h5>
                    </div>
                    <div className="my-3 col-xl-3 col-lg-3 col-md-6 col-6 text-center"
                        data-aos="fade-up"
                        data-aos-offset="0"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <img src={brad} alt="" className="img-fluid" />
                        <h5 className="py-2 text-white text-center">
                            Brad
                        </h5>
                        <h5 className="py-2 text-white text-center">
                            Co-founder

                        </h5>
                    </div>
                    <div className="my-3 col-xl-3 col-lg-3 col-md-6 col-6 text-center"
                        data-aos="fade-up"
                        data-aos-offset="0"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <img src={wiz} alt="" className="img-fluid" />
                        <h5 className="py-2 text-white text-center">
                            OxWiz
                        </h5>
                        <h5 className="py-2 text-white text-center">
                            Co-founder/ Dev
                        </h5>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Team;
