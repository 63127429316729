import React from "react";
import "./FAQ.css";
import Accordion from "./Accordion";
import { accordionData } from "./AccordionData";
const FAQ = () => {
  return (
    <>
      <div className="container-fluid " id="faq">
        <div className="row background- color-sections -white py-2">
          <div className="col-md-10 mx-auto my-3 py-3 bg-faq">
            <h2 className="section- heading faq text-center">Frequently Asked Questions</h2>
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;
