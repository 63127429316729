import attributes from "../../images/attributes.png";
import slide from "../../images/bg-3-mobile.png";
import "./Home.css"
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

const Home = () => {
    SwiperCore.use([Autoplay]);
    return (
        <>

            <div className="container pt-5" id="about">
                <div className="row laptop py-3 bg-banner1 home-section-one background-color-sections">
                    <div className="col-md-5 m-auto text-center "
                        data-aos="fade-up"
                        data-aos-offset="00"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <h1 className="text-center">What is Exclusive Shark Club?</h1>
                        <p>
                            Exclusive Shark Club is an interactive P2E collection of 5,000 randomly generated NFTs swimming in the Ethereum Blockchain, constructed from over 200 unique hand drawn traits. Every Shark comes with an assortment of colors, prints, faces, hats, bling, and extra items. Exclusive Sharks give owners the ability to evolve their Shark into its most exclusive form.
                        </p>
                    </div>
                    <div className="col-md-6 m-auto text-center"
                    >
                        {/* <img src={home1} alt="" /> */}
                    </div>

                </div>
                <div className="row mobile py-3  ">
                    <div className="col-md-6 m-auto text-center">
                        <img src={slide} alt="" />
                    </div>
                    <div className="col-md-6 bg-image home-section-one background-color-sections text-center p-2"
                        data-aos="fade-up"
                        data-aos-offset="00"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <h1 className="text-center">What is Exclusive Shark Club?</h1>
                        <p>
                            Exclusive Shark Club is an interactive P2E collection of 5,000 randomly generated NFTs swimming in the Ethereum Blockchain, constructed from over 200 unique hand drawn traits. Every Shark comes with an assortment of colors, prints, faces, hats, bling, and extra items. Exclusive Sharks give owners the ability to evolve their Shark into its most exclusive form.
                        </p>
                    </div>



                </div>
                <div className="row p-3 home-section-one">
                    <div className="col-md-6 bg-image background-color-sections text-center p-2"
                        data-aos="fade-up"
                        data-aos-offset="00"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-left"
                    >
                        <h1 className="text-center">Why get an Exclusive Shark?</h1>
                        <p>
                            Exclusive Sharks is a unique interactive community with many exclusive benefits. Aside from showing off your profile picture, you also gain access to exclusive community events as well as the ability evolve your Shark through our interactive P2E NFT game where you determine the final outcome of your Shark. Staking your Shark will earn holders $ESC. Every Shark is treated equally until you evolve your Shark.
                        </p>


                    </div>

                    <div className="col-md-6 bg-image background-color-sections mx-auto  text-center"
                        data-aos="fade-up"
                        data-aos-offset="00"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-right"
                    >
                        <h1 className="text-center">
                            Is mint going to break the bank? Nope!</h1>
                        <div className="row mx-auto my-3">
                            <div className="col-6 m-auto border-div bg-white text-center">
                                <p className="black-para text-center text-black">Exclusive</p>
                                <div className="border-div text-center">
                                    <p className="wec-para text-center my-auto">OG</p>
                                </div>
                            </div>
                            <div className="col-4 m-auto text-center">
                                <p className="wec-para text-center my-auto border-div " >= FREE</p>
                            </div>

                        </div>
                        <div className="row mx-auto my-3">
                            <div className="col-6 m-auto border-div bg-white text-center">
                                <p className="black-para text-center">Exclusive </p>
                                <div className="border-div text-center">
                                    <p className="wec-para text-center my-auto">First 2.5k</p>
                                </div>
                            </div>
                            <div className="col-4 m-auto text-center">
                                <p className="wec-para text-center my-auto border-div " >= FREE</p>
                            </div>

                        </div>
                        <div className="row mx-auto my-3">
                            <div className="col-6 m-auto border-div bg-white text-center">
                                <p className="black-para text-center">Exclusive</p>
                                <div className="border-div text-center">
                                    <p className="wec-para text-center my-auto">Public</p>
                                </div>
                            </div>
                            <div className="col-4 m-auto text-center">
                                <p className="wec-para text-center my-auto border-div  " >= 0.0077</p>
                            </div>

                        </div>


                    </div>


                </div>

                <div className="row py-3 home-section-one background-color-sections attribute" id="attributes">

                    <div className="col-md-6 mx-auto text-center p-2"
                        data-aos="fade-up"
                        data-aos-offset="100"
                        // data-aos-delay="25"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top-center"
                    >
                        <h1 className="text-center">Exclusive Shark Club Attributes</h1>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col ">Atttributes</th>
                                    <th scope="col">Rarity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">Background</td>
                                    <td>28</td>

                                </tr>
                                <tr>
                                    <td scope="row">Skin</td>
                                    <td>10</td>

                                </tr><tr>
                                    <td scope="row">Print</td>
                                    <td>7</td>

                                </tr><tr>
                                    <td scope="row">Nose</td>
                                    <td>11</td>

                                </tr><tr>
                                    <td scope="row">Hat</td>
                                    <td>43</td>

                                </tr>
                                <tr>
                                    <td scope="row">Eyes</td>
                                    <td>27</td>

                                </tr>
                                <tr>
                                    <td scope="row">Extra Item</td>
                                    <td>36</td>

                                </tr>
                                <tr>
                                    <td scope="row">Mouth</td>
                                    <td>22</td>

                                </tr>
                                <tr>
                                    <td scope="row">Body</td>
                                    <td>14</td>

                                </tr>
                                <tr>
                                    <td scope="row">Necklace</td>
                                    <td>5</td>

                                </tr>


                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6 m-auto">
                        <img src={attributes} alt="" />
                    </div>
                </div>

            </div>
        </>
    );
};
export default Home;