export const accordionData = [
  {
    title: "What is Exclusive Shark Club?",
    content: `Exclusive Shark Club is a collection of 7,777 randomly generated Sharks swimming in
the Ethereum blockchain. Exclusive Sharks give owners the ability to interact and
evolve their Shark in our unique P2E experience.`,
  },
  {
    title: "Why would I want to own a Exclusive Shark?",
    content: `There are thousands of NFTs out there, what makes ESC stand out? The benefits of owning a Exclusive Shark reach way further the just a dope profile picture. Sharks also give holders the ability to earn passive income with $ESC and evolve and mutate their Sharks. Your Shark is also your ticket to exclusive events and giveaways. `,
  },
  {
    title: "What is the mint price & max mint amount?",
    content: "OG sale- FREE (max 2 per wallet) Public sale- First 2.5k FREE (max 2 per wallet), the rest will be 0.0077 ETH (max 10)",
  },
  {
    title: "What is $ESC",
    content: `$ESC is the community token for the ESC ecosystem. $ESC will be deployed on the Ethereum blockchain as an ERC20 token. $ESC will be used to purchase items in the Evolution Shop, weekly mystery boxes, giveaways, merch, future drops and whitelist spots to other projects! Make sure to save it up! Without $ESC no mutations can happen!`,
  },
  {
    title: "Our promise!?",
    content: `To give holders valuable utility and be the most transparent NFT collection on the blockchain!`,
  },
  {
    title: "When can I mint?",
    content: `TBA`,
  },
];
