
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import slide1 from "../../images/0.png"
import slide2 from "../../images/1.png"
import slide3 from "../../images/2.png"
import slide4 from "../../images/3.png"
import slide5 from "../../images/4.png"
import slide6 from "../../images/5.png"

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import slide11 from "../../images/1.jpeg"
import slide12 from "../../images/2.jpeg"
import slide13 from "../../images/3.jpeg"
import slide14 from "../../images/4.jpeg"
import slide15 from "../../images/5.JPG"
import slide16 from "../../images/6.jpeg"

import "./Banner.css"

import middle from "../../images/middle.png"
import React from "react";
import { useState, useEffect } from "react";
const Banner = () => {
    //get screen width in pixels
    const width = window.innerWidth;
    const maxSize = 455;




    SwiperCore.use([Autoplay]);
    return (
        <div>

            <div className="container-fluid bg-banner mt-5 pt-5 ">
                <div className="d-flex margin-section">
                    {width > maxSize && (

                        <div className="vertical-slider my-auto">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={3}
                                direction={width > maxSize ? "vertical" : "horizontal"}
                                speed={width > maxSize ? 2000 : 2500}
                                loop={true}

                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                // scrollbar={{ draggable: false }}
                                breakpoints={{
                                    640: {
                                        // width: 640,
                                        width: 15,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        // width: 768,
                                        width: 15,
                                        slidesPerView: 3,
                                    },

                                    maxSize: {
                                        width: 15,
                                        slidesPerView: 2,
                                    }
                                }}
                            >
                                <SwiperSlide><img className="slider-image" src={slide1} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide2} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide3} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide4} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide5} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide6} /></SwiperSlide>

                                <SwiperSlide><img className="slider-image" src={slide1} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide2} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide3} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide4} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide5} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide6} /></SwiperSlide>

                            </Swiper>
                        </div>
                    )}
                    <div className="logo-section text-center px-5">
                        <img src={middle} alt="No image" />
                        <a href="/mint"> <button className="btn btn-primary text-center btn-font">Mint Now</button></a>
                    </div>
                    {width > maxSize && (

                        <div className="vertical-slider my-auto">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={3}
                                direction={width > maxSize ? "vertical" : "horizontal"}
                                speed={width > maxSize ? 2000 : 2500}
                                loop={true}

                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                // scrollbar={{ draggable: false }}
                                breakpoints={{
                                    640: {
                                        // width: 640,
                                        width: 20,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        // width: 768,
                                        width: 20,
                                        slidesPerView: 3,
                                    },

                                    maxSize: {
                                        width: 20,
                                        slidesPerView: 2,
                                    }
                                }}
                            >
                                <SwiperSlide><img className="slider-image" src={slide11} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide12} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide13} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide14} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide15} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide16} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide11} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide12} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide13} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide14} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide15} /></SwiperSlide>
                                <SwiperSlide><img className="slider-image" src={slide16} /></SwiperSlide>
                            </Swiper>

                        </div>

                    )}


                </div>

            </div>

            {width <= maxSize && (
                <div className="vertical-slider my-auto">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={3}
                        direction={width > maxSize ? "vertical" : "horizontal"}
                        speed={width > maxSize ? 2000 : 2500}
                        loop={true}

                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        // scrollbar={{ draggable: false }}
                        breakpoints={{
                            maxSize: {
                                width: 20,
                                slidesPerView: 2,
                            }
                        }}
                    >
                        <SwiperSlide><img className="slider-image" src={slide11} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide12} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide13} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide14} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide15} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide16} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide11} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide12} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide13} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide14} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide15} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide16} /></SwiperSlide>
                    </Swiper>

                </div>


            )}
            {width <= maxSize && (
                <div className="vertical-slider my-auto">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={3}
                        direction={width > maxSize ? "vertical" : "horizontal"}
                        speed={width > maxSize ? 2000 : 2500}
                        loop={true}

                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        // scrollbar={{ draggable: false }}
                        breakpoints={{
                            640: {
                                // width: 640,
                                width: 15,
                                slidesPerView: 1,
                            },
                            768: {
                                // width: 768,
                                width: 15,
                                slidesPerView: 3,
                            },

                            maxSize: {
                                width: 15,
                                slidesPerView: 2,
                            }
                        }}
                    >
                        <SwiperSlide><img className="slider-image" src={slide1} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide2} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide3} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide4} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide5} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide6} /></SwiperSlide>

                        <SwiperSlide><img className="slider-image" src={slide1} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide2} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide3} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide4} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide5} /></SwiperSlide>
                        <SwiperSlide><img className="slider-image" src={slide6} /></SwiperSlide>

                    </Swiper>
                </div>

            )}

        </div>
    );
};
export default Banner;

