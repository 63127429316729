import React, { useState } from "react";
import "./FAQ.css";
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="container"
    >
      <div className="row justify-content-center">
        <div className="col-md-8 mx-auto text-center faq-border mt-3">
          <div className="accordion-item ">
            <div
              className="accordion-title "
              onClick={() => setIsActive(!isActive)}

            >
              <div className="inlineLeft my-auto">{title}</div>
              <div className="inlineRight">{isActive ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}</div>
              <div className="clear"></div>
            </div>
            {isActive && (
              <div className="accordion-content p -0 m- 0 accContent">
                {
                  content != "OG sale- .05 max of 5  WL sale- .06 max of 10 Public sale- .08 max of 20" ?
                    <p>{content}</p> :
                    <p>OG sale- .05 max of 5 <br />WL sale- .06 max of 10<br /> Public sale- .08 max of 20</p>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
