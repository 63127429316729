import { React, useState, useEffect } from "react";
import Web3 from "web3";
import abi from "./abi.json";
import MintImage from "../../images/mint.png"
import "./Minting-section.css";
import { ethers } from "ethers";
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

require("dotenv").config();
//get api key from env file
const apiKey = process.env.REACT_APP_API_KEY;


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "testesc-11050.firebaseapp.com",
  projectId: "testesc-11050",
  storageBucket: "testesc-11050.appspot.com",
  messagingSenderId: "104883225085",
  appId: "1:104883225085:web:8fcbbfb4f698ae64989994",
  measurementId: "G-DZ3WG430QB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const Buy = ({ connecctstatus, setConnectedstatus }) => {
  const [connectedAccount, setConnectedAccount] = useState("CONNECT");
  const [Contract, setContract] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [supply, setTokenSupply] = useState(null);
  const [price, setPrice] = useState();
  const [priceInEth, setPriceInEth] = useState(0.0077);
  const [quantity, setQuantity] = useState(1);
  const [minted, setMinted] = useState(false);
  const [UserAddressSubstring, setUserAddressSubstring] = useState("");
  const [isPublicFreeLive, setIsPublicFreeLive] = useState(false);
  const [isOgLive, setIsOgLive] = useState(false);
  const [isPublicPaidLive, setIsPublicPaidLive] = useState(false);
  const [totalMinted, setTotalMinted] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accountString, setAccountString] = useState("");


  const [account, setAccount] = useState("");

  //quantity to a string
  const quantityToString = (quantity) => {
    return quantity.toString();
  }


  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      console.log("metamask detected");
      console.log(process.env.REACT_APP_CONTRACT_ADDRESS);
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(accounts[0]);
      setAccountString(accounts[0])
      //get first 7 characters of address
      setUserAddressSubstring(accounts[0].slice(0, 7) + "...");

      //connect to mint contract
      const provider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        '0xD84303Fb2815447AE0cd366633C9799288193645',
        abi,
        signer
      );
      setContract(contract);

      const free = await contract.isFreePublicLive();
      const og = await contract.isOgLive();
      const paid = await contract.isPaidPublicLive();
      console.log(`free: ${free}`);


      setIsPublicFreeLive(free);
      setIsOgLive(og);
      setIsPublicPaidLive(paid);


      //get total minted
      const _totalMinted = await contract.totalCounter();
      setTotalMinted(_totalMinted.toString());
    }
    else {
      alert("Metamask not detected");
    }
  }
  useEffect(() => {
    initConnection();



  }, []);

  //free mint
  const freePublicMint = async () => {
    //get publicFreeMint status from contract
    if (Contract) {
      const tx = await Contract.freeMintPublic(
        quantityToString(quantity)

      );
      console.log(`tx: ${tx}`);
      setMinted(true);
    }
  }
  async function fireBaseFunction() {
    const firebaseMerkle = httpsCallable(functions, "firebaseMerkle");
    await firebaseMerkle({
      walletAddress: account,
    }).then((result) => {
      const data = result.data;

      const proof = data.data;
      if (data.verified !== true) {
        setIsAuthenticated(false);
        alert("❌ Authentication failed, you are not whitelisted");
      } else {
        setIsAuthenticated(true);
        ogMint(proof);
        alert("✅ Authentication successful, you are whitelisted");
      }
    });
  }


  const paidMint = async () => {
    if (Contract) {
      const tx = await Contract.publicMintPaid(
        quantityToString(quantity),
        {
          value: ethers.utils.parseEther((priceInEth * quantity).toString()),
          gasLimit: 200000,
        }
      );
    }
  }
  const ogMint = async (_proof) => {
    if (Contract) {
      const tx = await Contract.ogMint(
        quantityToString(quantity),
        _proof,
      )
    }
  }



  return (
    <>

      <div id="Buynft" className="Buynft ">
        <div className="container -fluid p-5 ">
          <h1 className="mint-heading text-center">Welcome Shark {UserAddressSubstring}</h1>



          <div className="row mx-auto text-center">
            {/* <div className="col-md-6 mx-auto  text-center">
              <img className="image" src={MintImage} alt="Pic" />
            </div> */}
            <div className="col-md-6 mx-auto"
              data-aos="fade-up"
              data-aos-offset="00"
              // data-aos-delay="25"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-anchor-placement="top-center"
            >
              <div className="col-md-12 btngroup ">
                <h1 className="mint-heading text-center">Mint Status: {isOgLive ? "OG/Whitelisted MINT Only" : "Public Sale"}</h1>
                <div className="row border-btm">
                  <div className="col-md-12 mint-col">
                    <h4 style={{ color: "white" }}>Price</h4>
                    {/* Change free to priceInEth before going live!!!!!!!!!!!! Important */}
                    <h4 style={{ color: "white" }} className="float-right ">{isOgLive || isPublicFreeLive ? 0 :
                      priceInEth * quantity}</h4>
                  </div>
                </div>
                <div className="row border-btm">
                  <div className="col-md-12 mint-col">
                    <h4 style={{ color: "white" }} className="pt-2">Quantity</h4>
                    <div
                      className=" rounded btngroup "
                      role="group"
                      aria-label="First group"
                    >
                      <button


                        className=" increment-btn m-0 "
                        onClick={() => {
                          if (quantity > 1) {
                            setQuantity(quantity - 1);
                          }
                        }}
                      >
                        -
                      </button>
                      <h4 style={{ color: "white" }} >{quantity}</h4>

                      <button
                        className=" increment-btn  p-0"
                        onClick={() => {
                          if (quantity < 10) {
                            setQuantity(quantity + 1);
                          }
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row border-btm">
                  <div className="col-md-12 mint-col">
                    <h4 style={{ color: "white" }}>Total Minted</h4>
                    {/* Change 7777 to supply when contract is live */}
                    <h4 style={{ color: "white" }} className="float-right">{totalMinted}/5000</h4>
                  </div>
                </div>
                {/* <div className="row border-btm">
                  <div className="col-md-12 mint-col">
                    <h4 style={{ color: "white" }}>Remaining</h4>
                    <h4 style={{ color: "white" }} className="float-right">{minted}</h4>
                  </div>
                </div> */}
                <p className="text-center py-5">
                  <button class="btn btn-primary text-center btn-font"
                    onClick={async () => {
                      if (isPublicFreeLive) {
                        freePublicMint();
                      }
                      else if (isPublicPaidLive) {
                        paidMint();
                      }
                      else if (isOgLive) {
                        fireBaseFunction()

                        //  ogMint();
                      }
                      else {
                        alert("Minting is either not live yet or you need to refresh the page");
                      }

                      // await initConnection();
                      // await ogMint();
                      // await freePublicMint();
                      // await paidMint();
                    }}
                  > Mint Now
                    <div class="c-button__blobs">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                  <svg style={{ display: "block", height: "0", width: "0" }} version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <filter id="goo">
                        <feGaussianBlur result="blur" stdDeviation="10" in="SourceGraphic"></feGaussianBlur>
                        <feColorMatrix result="goo" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" mode="matrix" in="blur"></feColorMatrix>
                        <feBlend in2="goo" in="SourceGraphic"></feBlend>
                      </filter>
                    </defs>
                  </svg>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );

};
export default Buy;