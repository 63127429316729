import React from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import { useState, useEffect } from 'react';
import middle from "../../images/middle.png"
import "./LoadingPage.css"

export default function LoadingPage() {
    const [paused1, setPaused1] = useState(false);
    useEffect(() => {
        setPaused1(true);
        setTimeout(() => {
            setPaused1(false);
        }
            , 1000);
    }
        , []);

    return (
        <div className="center-screen">
            <motion.img src={middle} alt="loading"
                initial={{
                    opacity: 0.1,
                    scale: 0.1,
                    rotate: 360
                }}

                animate={{
                    opacity: 1,
                    scale: .5,
                    rotate: paused1 ? 360 : 0,
                    transition: {
                        duration: 3,
                        ease: "easeInOut"
                    }


                }}



            />
        </div>
    )



}