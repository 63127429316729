import Home from "./components/Home/Home"
import Navbar from './Navbar.js';
import Team from "./components/Team/Team";
import FAQ from "./components/FAQ/FAQ";
import Roadmap from "./components/Roadmap/Roadmap";
import Footer from "./components/Footer/Footer";
import MintingSection from "./components/Minting-section/Minting-section";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState, useEffect } from "react";
import { GoAlert } from "react-icons/go"
import { Button, Modal } from 'react-bootstrap';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Banner from "./components/Home/Banner"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LoadingPage from "./components/Animation/LoadingPage";
import Shark from "./components/Animation/Shark";
function App() {
  AOS.init();

  const [loading, setLoading] = useState(false);
  const [summonPete, setSummonPete] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }
    , []);
  //dont let the screen be landscape
  useEffect(() => {
    window.addEventListener("orientationchange", function () {
      if (window.orientation === 90 || window.orientation === -90) {
        alert("Please use portrait mode");
      }
    });
  }
    , []);
  return (
    <>

      <Switch>
        <Route exact path="/mint" >
          <MintingSection />
        </Route>
        {loading ? <LoadingPage /> : (
          <>
            <Navbar />
            <Route path="/" >
              <Banner />
              <Home />
              <Roadmap />
              <Team />
              <FAQ />
            </Route>

            <Footer />

          </>
        )}
      </Switch>
    </>

  );
}

export default App;


function PopUp() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header  >
          <Modal.Title >Attention <GoAlert /> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We recommend updating your iPhone to the latest iOS for best viewing experience. If you're using an Outdated iOS device
          some images on the Minting site may look slightly altered due to non updated software on your device.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
