import './css/navbar.css'
import { Button, Modal } from 'react-bootstrap';
import { FaDiscord, FaTwitter, FaFacebookF, FaFacebook, FaInstagram } from "react-icons/fa"
import { useState } from "react"
import { Link } from "react-router-dom"
import logo from './images/navbar-logo.png'
import { Icon } from '@mui/material';
import rsLogo from './images/rsLogo.ico'
import logo1 from './images/logo1.png'

function Navbar() {
    const [valShow, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    return (
        <nav className="navbar navbar-dark bg-black fixed- top navbar-expand-md">
            <div className="container -fluid">
                <div className="navbar-brand">

                    <a href="#">
                        <img src={logo} alt="logo" className="logonav" />

                    </a>


                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                    <ul className="navbar-nav  mt- 2 mt- lg-0 mx-auto">

                        <li className="nav-item">
                            <a className="nav-link" href="/#about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#attributes">Attributes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#roadmap">Roadmap</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#team">Team</a>
                        </li>
                        <li className="nav-item">
                            <button onClick={handleOpen} type="button" class="nav-btn" >
                                Staking
                            </button>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#faq">Faq</a>
                        </li>
                        <li className="nav-item" >
                            <a className="nav-link" href="https://mirror.xyz/0xA4073eF5Daf9E279EEc2EB43f684492D2bF0C1E5/qUjhkF3HNImVa6ejNkMHPsw-XOG44hzUu_akGSjR8dU">Shark Paper</a>
                        </li>


                    </ul>
                    <div className="icons">
                        <a className="twitter" href="https://twitter.com/ExclusiveSharks" target="_blank">
                            <FaTwitter className="social-icon" />
                        </a>
                        <a className="discord" href="https://discord.gg/QVCRBBkw4h" target="_blank">
                            <FaDiscord className="discord" />
                        </a>
                        <a href="https://raritysniper.com/nft-drops-calendar" target="_blank">
                            <img src={logo1} alt="rs3" className="rsLogo" />
                        </a>



                    </div>

                </div>


            </div>
            <Modal className="Modal"
                show={valShow}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
                animation={false}
            >
                <Modal.Header  >
                    <Modal.Title className="text-center" >Coming Soon </Modal.Title>
                </Modal.Header>
                {/* <Modal.Body >
            
          </Modal.Body> */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>


        </nav>

    );

}


export default Navbar;

function PopUp() {

    return (
        <>

        </>
    );
}