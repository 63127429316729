import "./Roadmap.css"
import roadmap from "../../images/finalRM2.png"
function Roadmap() {
    return (
        <>
            <div className="container home-section-one background-color-sections bg-team" id="roadmap">
                <div class="col-lg-12">
                    <img src={roadmap} className='rm' alt="" />
                    {/* <div class="card">
                            <div class="card-body ">
                                <h1 class="">ROADMAP</h1>

                                <div class="hori-timeline" dir="ltr">
                                    <ul class="list-inline events">
                                        <li class="list-inline-item event-list"
                                            data-aos="fade-up"
                                            data-aos-offset="0"
                                            // data-aos-delay="25"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out"
                                            data-aos-mirror="true"
                                            data-aos-once="false"
                                            data-aos-anchor-placement="top-center"
                                        >
                                            <div class="box-shad mx-4">
                                                <div className="pink-div-head">
                                                    <h5 class="font-size-16 text-black ">Mint</h5>
                                                </div>
                                                <div className="pink-div-para">
                                                    <p class=" text-white text-left">0% Merch Launch <br /><br />
                                                        25%: Donation to The Ocean Cleanup & Shark Advocates<br /><br />
                                                        50%: All Royalties back to The community<br /><br />
                                                        75%:5 1 of 1s raffled to Exclusive holders<br /><br />
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-inline-item event-list"
                                            data-aos="fade-up"
                                            data-aos-offset="0"
                                            // data-aos-delay="25"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out"
                                            data-aos-mirror="true"
                                            data-aos-once="false"
                                            data-aos-anchor-placement="top-center"
                                        >
                                            <div class="box-shad mx-4">
                                                <div className="blue-div-head">
                                                    <h5 class="font-size-16 text-black ">Q2 2022</h5>
                                                </div>
                                                <div className="blue-div-para">
                                                    <p class=" text-white text-left">Core team expansion
                                                        <br /><br />
                                                        Partnership & Collabs<br /><br />
                                                        Land in Sandbox for Sharks<br /><br />
                                                        Exclusive raffles to Shark holders<br /><br />
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-inline-item event-list"
                                            data-aos="fade-up"
                                            data-aos-offset="0"
                                            // data-aos-delay="25"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out"
                                            data-aos-mirror="true"
                                            data-aos-once="false"
                                            data-aos-anchor-placement="top-center"
                                        >
                                            <div class="box-shad mx-4">
                                                <div className="yellow-div-head">
                                                    <h5 class="font-size-16 text-black ">Q3 2022</h5>
                                                </div>
                                                <div className="yellow-div-para">
                                                    <p class=" text-white text-left">$ESC Token Launch
                                                        <br /><br />Staking Sharks
                                                        <br /><br />Evolving Sharks
                                                        <br /><br />Mystery Boxes
                                                        <br /><br />Liquidity pools launched
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-inline-item event-list"
                                            data-aos="fade-up"
                                            data-aos-offset="0"
                                            // data-aos-delay="25"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out"
                                            data-aos-mirror="true"
                                            data-aos-once="false"
                                            data-aos-anchor-placement="top-center"
                                        >
                                            <div class="box-shad mx-4">
                                                <div className="purple-div-head">
                                                    <h5 class="font-size-16 text-black ">Q4 2022</h5>
                                                </div>
                                                <div className="purple-div-para">
                                                    <p class=" text-white text-left">Mutant fish launch
                                                        <br /><br />PARTYTIME!
                                                        <br /><br />Real-life events for Our Exclusive community
                                                    </p>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
        </>
    );
}
export default Roadmap;
